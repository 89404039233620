import Vue from "vue";
import Vuetify from "vuetify/lib";
import tr from "vuetify/src/locale/tr";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#7101d0",
        secondary: "#992379",
        navbar: "#280148",
        navbarText: "#fff"
      }
    }
  },
  lang: {
    locales: { tr },
    current: "tr"
  }
});
