import Vue from "vue";
import router from "../router";
import VueGtag from "vue-gtag";

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-QH8Y71QEE8"
    }
  },
  router
);

export default VueGtag;
