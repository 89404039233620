import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

// Firebase configs
const firebaseConfig = {
  apiKey: "AIzaSyB0uBAaTvM5_rS5_otmOogqEKL-mR12H9U",
  authDomain: "ute-akademi.firebaseapp.com",
  databaseURL: "https://ute-akademi.firebaseio.com",
  projectId: "ute-akademi",
  storageBucket: "ute-akademi.appspot.com",
  messagingSenderId: "363351780837",
  appId: "1:363351780837:web:bf6c1b76feb3342720930c",
  measurementId: "G-QH8Y71QEE8"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
