import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import("../layouts/home/Index.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../views/home/Index.vue")
      },
      {
        path: "/kullanim-sartlari",
        name: "terms-of-use",
        component: () => import("../views/TermsOfUse.vue")
      },
      {
        path: "/gizlilik-politikasi",
        name: "privacy-policy",
        component: () => import("../views/PrivacyPolicy.vue")
      },
      {
        path: "/kvk",
        name: "personal-data-protection",
        component: () => import("../views/PersonalDataProtection.vue")
      }
    ]
  },
  {
    path: "",
    component: () => import("../layouts/account/Index.vue"),
    children: [
      {
        path: "/davetiye/:id",
        name: "invitation",
        component: () => import("../views/account/Invitation.vue")
      },
      {
        path: "/sv-davetiye/:id",
        name: "sv-invitation",
        component: () => import("../views/account/SupervisorInvitation.vue")
      },
      {
        path: "/giris",
        name: "signin",
        component: () => import("../views/account/SignIn.vue")
      },
      {
        path: "/parolami-unuttum",
        name: "forgot-password",
        component: () => import("../views/account/ForgotPassword.vue")
      }
    ]
  },
  {
    path: "/derslik",
    component: () => import("../layouts/classroom/Index.vue"),
    children: [
      {
        path: "",
        name: "my-courses",
        component: () => import("../views/classroom/MyCourses.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ayarlar",
        name: "settings",
        component: () => import("../views/classroom/Settings.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "egitim-katalogu",
        name: "course-catalog",
        component: () => import("../views/classroom/course-catalog/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "egitim-katalogu/:courseUrlCode",
        name: "course-catalog-detail",
        component: () => import("../views/classroom/course-catalog/Detail.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/egitimler/:courseUrlCode",
    component: () => import("../layouts/course/Index.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        name: "course-home",
        component: () => import("../views/course/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "konular",
        name: "course-lessons",
        component: () => import("../views/course/Lessons.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "tartismalar",
        name: "course-discussions",
        component: () => import("../views/course/discussions/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "tartismalar/:id",
        name: "course-discussion",
        component: () => import("../views/course/discussions/View.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/admin",
    component: () => import("../layouts/admin/Index.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        name: "admin-home",
        component: () => import("../views/admin/Index.vue")
      },
      {
        path: "kullanicilar",
        name: "admin-users",
        component: () => import("../views/admin/users/Index.vue")
      },
      {
        path: "egitimler",
        name: "admin-courses",
        component: () => import("../views/admin/courses/Index.vue")
      },
      {
        path: "ayarlar",
        name: "admin-settings",
        component: () => import("../views/admin/Settings.vue")
      }
    ]
  },
  {
    path: "/supervisor",
    component: () => import("../layouts/supervisor/Index.vue"),
    meta: {
      requiresAuth: true,
      authRole: "supervisor"
    },
    children: [
      {
        path: "",
        name: "supervisor-home",
        component: () => import("../views/supervisor/Index.vue")
      },
      {
        path: "kullanicilar",
        name: "supervisor-users",
        component: () => import("../views/supervisor/users/Index.vue")
      },
      {
        path: "egitimler",
        name: "supervisor-courses",
        component: () => import("../views/supervisor/courses/Index.vue")
      },
      {
        path: "ayarlar",
        name: "supervisor-settings",
        component: () => import("../views/supervisor/Settings.vue")
      }
    ]
  },
  {
    path: "/supervisor/egitimler/:courseUrlCode",
    component: () => import("../layouts/supervisor/course/Index.vue"),
    children: [
      {
        path: "",
        name: "supervisor-course-home",
        component: () => import("../views/supervisor/course/Index.vue")
      },
      {
        path: "konular",
        name: "supervisor-course-lessons",
        component: () => import("../views/supervisor/course/Lessons.vue")
      },
      {
        path: "tartismalar",
        name: "supervisor-course-discussions",
        component: () =>
          import("../views/supervisor/course/discussions/Index.vue")
      },
      {
        path: "tartismalar/:id",
        name: "supervisor-course-discussion",
        component: () =>
          import("../views/supervisor/course/discussions/View.vue")
      }
    ]
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("../views/404.vue")
  }
];

const userCanAccess = function(
  to: Route,
  isAdminRoute: boolean,
  isSupervisorRoute: boolean
) {
  const userSignedIn = store.getters["auth/userSignedIn"];
  const user = store.getters["auth/user"];
  const authRole = to.meta?.authRole;
  const isAdmin = userSignedIn ? user.roles.indexOf("admin") >= 0 : false;
  const isSupervisor = userSignedIn
    ? user.roles.indexOf("supervisor") >= 0
    : false;

  // Eğer Route /supervisor içindeyse authRole'a bakmadan
  // kullanıcının admin rolünde olup olmadığını sorgula
  if (isSupervisorRoute) {
    if (isSupervisor) {
      return true;
    } else {
      return false;
    }
  }

  // Eğer Route /admin içindeyse authRole'a bakmadan
  // kullanıcının admin rolünde olup olmadığını sorgula
  if (isAdminRoute) {
    if (isAdmin) {
      return true;
    } else {
      return false;
    }
  }

  // Kullanıcı oturum açmış mı?
  if (userSignedIn) {
    // Route için bir rol belirlenmiş ise izin ver
    // Yoksa kullanıcının rolünü kontrol et
    if (!authRole) {
      return true;
    } else {
      // Admin ve supervisor ise izin ver
      if (isAdmin || isSupervisor) {
        return true;
      }
      // Kullanıcı istenilen yetki rolünde mi?
      else {
        return user.roles.indexOf(to.meta?.authRole.toLowerCase()) >= 0;
      }
    }
  } else {
    return false;
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const isAdminRoute = /^\/admin/.test(to.path);
  const isSupervisorRoute = /^\/supervisor/.test(to.path);
  const requiresAuth =
    to.matched.some(record => record.meta.requiresAuth) ||
    isAdminRoute ||
    isSupervisorRoute;

  if (requiresAuth) {
    if (userCanAccess(to, isAdminRoute, isSupervisorRoute)) {
      next();
    } else {
      next({
        name: "signin",
        query: { returnUrl: to.path }
      });
    }
  } else {
    next();
  }
});

export default router;
