/* eslint-disable @typescript-eslint/no-explicit-any */
import firebase from "@/plugins/firebase";
import "@/types";
import axios from "axios";
import router from "@/router";

const lms = {
  namespaced: true,

  state: {},

  getters: {},

  mutations: {},

  actions: {
    addCourseReg: async (
      context: any,
      prms: { uid: string; courseId: string }
    ) => {
      const timestamp = firebase.firestore.Timestamp.now();
      const cregRef = firebase
        .firestore()
        .collection("/businessCourseRegistrations");

      const cregCheck = await cregRef
        .where("uid", "==", prms.uid)
        .where("courseId", "==", prms.courseId)
        .get();

      if (cregCheck.empty) {
        await cregRef.add({
          uid: prms.uid,
          courseId: prms.courseId,
          dateCreated: timestamp,
          dateGraduated: null,
          progress: 0,
          isActive: true,
          roleId: "student"
        });
      }
    },

    removeCourseReg: async (context: any, cregId: string) => {
      await firebase
        .firestore()
        .doc(`/businessCourseRegistrations/${cregId}`)
        .delete();
    },

    //TODO: Yeni kullanıcı deneyimi loglama sistemini bitir.
    // Şu an sadece kullanıcıların ders içindeki etkinlikleri lessonLogs'a yazılıyor.
    // Oturum açma ve diğer tüm kullanıcı etkinliklerini de yeni sistemde logla.
    saveUserLog: async (
      context: any,
      payload: {
        verb: "signIn";
        meta: any;
      }
    ) => {
      const user = context.rootGetters["auth/user"];
      const userSignedIn = context.rootGetters["auth/userSignedIn"];

      if (userSignedIn) {
        try {
          // Get user ipinfos
          const ipInfoResponse = await axios.get(
            `https://ipinfo.io/json?token=d2efacd0f64aa6`
          );
          const client = ipInfoResponse.data;

          const newExp = {
            verb: payload.verb,
            meta: payload.meta ? payload.meta : null,
            desc: "",
            user: {
              uid: user.id,
              email: user.email,
              displayName: user.displayName
            },
            route: {
              path: router.currentRoute.fullPath,
              params: router.currentRoute.params
            },
            client: client,
            date: new Date(Date.now())
          };

          // Mesajı düzenle
          switch (payload.verb) {
            case "signIn":
              newExp.desc = `${
                newExp.user.displayName
              } adlı kullanıcı ${newExp.date.toLocaleString(
                "tr-TR"
              )} tarihinde sistemde oturum açtı.`;
          }

          await firebase
            .firestore()
            .collection("userLogs")
            .add(newExp);
        } catch (error) {
          throw `Logging failed: ${error}`;
        }
      }
    }
  }
};

export default lms;
