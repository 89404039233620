import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "./plugins/firebase";
import "./plugins/vue-meta";
import "./plugins/vue-notification";
import "./plugins/vue-tawk";
import "./plugins/vue-gtag";
import "@/plugins/json-excel";

Vue.config.productionTip = false;

// Check auth state change
firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    // Auto sign-in
    await store.dispatch("auth/signIn", {
      id: user.uid,
      displayName: user.displayName,
      email: user.email
    });
  }
  // else {
  //   // Sign-out
  //   await store.dispatch("auth/signOut");
  // }

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount("#app");
});
