/* eslint-disable @typescript-eslint/no-explicit-any */
import firebase from "@/plugins/firebase";
import Vuetify from "@/plugins/vuetify";

interface Business {
  id: string;
  code: string;
  name: string;
  regId: string;
  logo: string;
  colors: {
    primary: string;
    secondary: string;
    navbar: string;
    navbarText: string;
  };
  registrations: BusinessRegistration[];
}

interface BusinessGroup {
  id: string;
  code: string;
  name: string;
  logo: string;
  colors: {
    primary: string;
    secondary: string;
    navbar: string;
    navbarText: string;
  };
  businesses: Business[];
}

interface BusinessRegistration {
  id: string;
  name: string;
  dateCreated: Date;
  dateExpired: Date;
  disabled: boolean;
  maxUsers: number;
  activeUsers: number;
  passiveUsers: number;
}

const auth = {
  namespaced: true,
  state: {
    userSignedIn: false,

    user: {
      id: null,
      displayName: null,
      email: null,
      roles: [] as string[]
    },

    invitation: null,

    supervisorInvitation: null,

    business: {} as Business,

    businessGroup: {} as BusinessGroup,

    businessRegistration: {} as BusinessRegistration,

    defaultTheme: {
      primary: "",
      secondary: "",
      navbar: "",
      navbarText: ""
    }
  },
  getters: {
    user(state: any) {
      return state.user;
    },

    userSignedIn(state: any) {
      return state.userSignedIn;
    },

    invitation(state: any) {
      return state.invitation;
    },

    supervisorInvitation(state: any) {
      return state.supervisorInvitation;
    },

    business(state: any) {
      return state.business;
    },

    businessGroup(state: any) {
      return state.businessGroup;
    },

    businessRegistration(state: any) {
      return state.businessRegistration;
    },

    defaultTheme(state: any) {
      return state.defaultTheme;
    }
  },
  mutations: {
    setUserSignedIn(state: any, payload: any) {
      state.userSignedIn = payload;
    },

    setUser(state: any, payload: any) {
      state.user = payload;
    },

    setInvitation(state: any, invitation: any) {
      state.invitation = invitation;
    },

    setSupervisorInvitation(state: any, invitation: any) {
      state.supervisorInvitation = invitation;
    },

    setBusiness(state: any, business: any) {
      state.business = business;
    },

    setBusinessGroup(state: any, businessGroup: any) {
      state.businessGroup = businessGroup;
    },

    setBusinessRegistration(
      state: any,
      businessRegistration: BusinessRegistration
    ) {
      state.businessRegistration = businessRegistration;

      const b = state.business;
      b.regId = businessRegistration.id;
      state.business = b;
    },

    setDefaultTheme(state: any, theme: any) {
      state.defaultTheme = theme;
    }
  },
  actions: {
    async signIn(context: any, payload: any) {
      const user = {
        id: payload.id,
        displayName: payload.displayName,
        email: payload.email,
        roles: [] as string[]
      };

      const business = {} as Business;

      const businessGroup = {} as BusinessGroup;

      const businesses = [] as Business[];

      // Kullanıcının bilgilerini al.
      // Kullanıcı eğer kurumsal değilse oturumu kapat.
      const qsUserInv = await firebase
        .firestore()
        .collection("/businessUserInvitations")
        .where("uid", "==", user.id)
        .orderBy("dateCreated", "desc")
        .get();

      if (!qsUserInv.empty) {
        const doc = qsUserInv.docs[0];
        const userRecord = doc.data();
        user.roles.push(userRecord.roleId);

        // Kullanıcının kurum bilgilerini al
        business.code = doc.data().businessCode;
        business.id = doc.data().businessId;
        business.name = doc.data().businessName;
        business.regId = doc.data().businessRegId;
        business.registrations = [] as BusinessRegistration[];

        // Lisans kayıtlarını al
        const qsBrs = await firebase
          .firestore()
          .collection("/businessRegistrations")
          .where("businessId", "==", business.id)
          .orderBy("dateCreated", "desc")
          .get();

        if (!qsBrs.empty) {
          qsBrs.docs.forEach(brDoc => {
            const br = {} as BusinessRegistration;

            br.id = brDoc.id;
            br.disabled = brDoc.data().disabled;
            br.dateCreated = brDoc.data().dateCreated.toDate();
            br.dateExpired = brDoc.data().dateExpired.toDate();
            br.maxUsers = brDoc.data().maxUsers;
            br.activeUsers = brDoc.data().activeUsers;
            br.passiveUsers = brDoc.data().passiveUsers;
            br.name = br.dateCreated.getFullYear().toString();

            business.registrations.push(br);
          });
        }

        // Kurum detaylarını al
        const bDoc = await firebase
          .firestore()
          .doc(`/businesses/${business.id}`)
          .get();

        if (bDoc.exists) {
          business.logo = bDoc.data()?.logo;
          business.colors = bDoc.data()?.colors;
        }
      }

      // Supervisor bilgilerini al.
      // Kullanıcı eğer kurumsal değilse oturumu kapat
      const qsSvInv = await firebase
        .firestore()
        .collection("businessSupervisorInvitations")
        .where("uid", "==", user.id)
        .get();

      if (!qsSvInv.empty) {
        const doc = qsSvInv.docs[0];
        user.roles.push("supervisor");

        // Grup bilgilerini al
        businessGroup.code = doc.data().businessGroupCode;
        businessGroup.id = doc.data().businessGroupId;
        businessGroup.name = doc.data().businessGroupName;

        // Grup detaylarını al
        const gDoc = await firebase
          .firestore()
          .doc(`/businessGroups/${businessGroup.id}`)
          .get();

        if (gDoc.exists) {
          businessGroup.logo = gDoc.data()?.logo;
          businessGroup.colors = gDoc.data()?.colors;

          // Gruba ait işyerlerini al
          const businessIds: string[] = gDoc.data()?.businessIds;

          businessIds.forEach(async v => {
            // İşyeri detaylarını al
            const bDoc = await firebase
              .firestore()
              .doc(`/businesses/${v}`)
              .get();

            if (bDoc.exists) {
              const newItem: Business = {
                id: bDoc.id,
                code: bDoc.data()?.code,
                name: bDoc.data()?.name,
                regId: "",
                registrations: [] as BusinessRegistration[],
                logo: bDoc.data()?.logo,
                colors: bDoc.data()?.colors
              };

              // RegId bul
              const qsBusinessRegs = await firebase
                .firestore()
                .collection("businessRegistrations")
                .where("businessId", "==", newItem.id)
                .where("disabled", "==", false)
                .get();

              if (!qsBusinessRegs.empty) {
                newItem.regId = qsBusinessRegs.docs[0].id;
              }

              businesses.push(newItem);
            }
          });

          businessGroup.businesses = businesses;
        }
      }

      if (qsUserInv.empty && qsSvInv.empty) {
        throw "HATA: Kullanıcı bilgileri bulunamadığından giriş yapılamadı.";
      }

      context.commit("setUser", user);
      context.commit("setUserSignedIn", true);
      context.commit("setBusiness", business);
      context.commit("setBusinessGroup", businessGroup);

      // Deneyimi kaydet
      context.dispatch(
        "lms/saveUserLog",
        {
          verb: "signIn"
        },
        { root: true }
      );
    },

    async signOut(context: any) {
      await firebase.auth().signOut();

      context.commit("setUserSignedIn", false);
      context.commit("setUser", null);
      context.commit("setBusiness", null);
      context.commit("setBusinessGroup", null);

      // Varsayılan temaya dönmek için sayfayı yeniden yükle
      window.location.reload();
    },

    async saveInvitation(context: any, invitation: any) {
      context.commit("setInvitation", invitation);
    },

    async saveSupervisorInvitation(context: any, invitation: any) {
      context.commit("setSupervisorInvitation", invitation);
    },

    async sendInvitationEmail(context: any, invitationId: string) {
      try {
        const func = firebase
          .functions()
          .httpsCallable("email-sendInvitationEmail");

        await func({
          invitationId: invitationId
        });
      } catch (err) {
        const e = err as firebase.firestore.FirestoreError;
        throw new Error(`Davetiye gönderilemedi. ${e.message}`);
      }
    },

    changeTheme(context: any, name: "business" | "businessGroup" | "default") {
      if (name == "business") {
        // Varsayılan temayı sakla
        const theme = {
          primary: Vuetify.framework.theme.themes.light.primary,
          secondary: Vuetify.framework.theme.themes.light.secondary,
          navbar: Vuetify.framework.theme.themes.light.navbar,
          navbarText: Vuetify.framework.theme.themes.light.navbarText
        };

        context.commit("setDefaultTheme", theme);

        // Temayı değiştir
        const business = context.getters.business;

        Vuetify.framework.theme.themes.light.primary = business.colors.primary;
        Vuetify.framework.theme.themes.light.secondary =
          business.colors.secondary;
        Vuetify.framework.theme.themes.light.navbar = business.colors.navbar;
        Vuetify.framework.theme.themes.light.navbarText =
          business.colors.navbarText;
      } else if (name == "businessGroup") {
        // Varsayılan temayı sakla
        const theme = {
          primary: Vuetify.framework.theme.themes.light.primary,
          secondary: Vuetify.framework.theme.themes.light.secondary,
          navbar: Vuetify.framework.theme.themes.light.navbar,
          navbarText: Vuetify.framework.theme.themes.light.navbarText
        };

        context.commit("setDefaultTheme", theme);

        // Temayı değiştir
        const businessGroup = context.getters.businessGroup;

        Vuetify.framework.theme.themes.light.primary =
          businessGroup.colors.primary;
        Vuetify.framework.theme.themes.light.secondary =
          businessGroup.colors.secondary;
        Vuetify.framework.theme.themes.light.navbar =
          businessGroup.colors.navbar;
        Vuetify.framework.theme.themes.light.navbarText =
          businessGroup.colors.navbarText;
      } else {
        const defaultTheme = context.getters.defaultTheme;

        if (defaultTheme.primary) {
          // Varsayılan temaya dön
          Vuetify.framework.theme.themes.light.primary = defaultTheme.primary;
          Vuetify.framework.theme.themes.light.secondary =
            defaultTheme.secondary;
          Vuetify.framework.theme.themes.light.navbar = defaultTheme.navbar;
          Vuetify.framework.theme.themes.light.navbarText =
            defaultTheme.navbarText;
        }
      }
    },

    async changeBusiness(context: any, business: any) {
      context.commit("setBusiness", business);
    },

    async changeBusinessRegistration(
      context: any,
      businessRegistration: BusinessRegistration
    ) {
      context.commit("setBusinessRegistration", businessRegistration);
    }
  }
};

export default auth;
