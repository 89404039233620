








import Vue from "vue";

export default Vue.extend({
  name: "App",
  metaInfo: {
    title: "Hoş geldiniz!",
    titleTemplate: "%s - UTEx",
    htmlAttrs: { lang: "tr" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
});
